
import { defineComponent, onMounted } from "vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import { MenuComponent } from "@/assets/ts/components";

export default defineComponent({
  name: "dashboard",
  components: {},
  setup() {
    onMounted(() => {
      setCurrentPageTitle("Overview");
      MenuComponent.reinitialization();
    });
  },
});
